<template>
  <div class="synopsis">
    <div class="header">
      <div class="header-img">
        <div style="display: flex;" @click="jumpToHome">
          <div>
            <img style="width:0.75rem;height:0.75rem;object-fit:cover;" src="../../static/images/home-b-t.png" alt="">
          </div>
          <div style="margin-top: 0.1rem;">
            <img style="width:1.47rem;height:0.49rem;object-fit:cover;vertical-align: super;margin-left: 0.1rem;" src="../../static/images/home-b-a.png" alt="">
          </div>
        </div>
        <div class="right" @click="jumpToHome1">
          <img style="width:0.68rem;height:0.57rem;object-fit:cover;" src="../../static/images/home-s-b.png" alt="">
        </div>
      </div>
      <div class="header-text">ABOUT US</div>
    </div>
    <div class="synopsis-content">
      <div class="content-enterprise">
        <div class="enterprise-title">
          <div class="enterprise-title-top">
            <!-- 企业简介 -->
            {{$t('m.about.synopsis')}}
          </div>
          <div class="enterprise-title-bottom">Company profile</div>
        </div>
        <div class="enterprise-sub">
          <!-- 艾尔伴家 -->
          {{$t('m.about.synopsis_1')}}
          <span class="span-since">SINCE 2016 <span class="since"></span></span>
          <!-- 创立于重庆 -->
          {{$t('m.about.synopsis_2')}}
        </div>
        <p class="enterprise-p">
          <!-- 重庆市艾尔伴家科技有限公司是专业从事智慧社区建设和大数据分析应用的国家高新技术企业、留学回国人员创业示范单位和重庆英才大会优秀引进企业。 -->
          {{$t('m.about.synopsis_3')}}{{$t('m.about.synopsis_4')}}
        </p>
        <p class="enterprise-p">
          <!-- 公司通过“互联网、物联网、云计算、大数据”四大核心技术与人工智能深度融合，广泛应用于智慧城市建设中，为智慧城市、社会治理、大数据产业等国家重大战略发展规划提供一体化解决方案。 -->
          {{$t('m.about.synopsis_5')}}{{$t('m.about.synopsis_6')}}
        </p>
      </div>
      <div class="content-management">
        <div class="enterprise-title">
          <div class="enterprise-title-top">经营理念</div>
          <div class="enterprise-title-bottom">BUSINESS CONCEPT</div>
        </div>
        <div class="management-box">
          <div ref="animate1" class="solution animate__animated">
            <div class="solution-left">
              <div class="left-num">
                <span style="position: relative;">586 <span style="position: absolute;right:-0.4rem;top:-0.1rem;">+</span></span>
              </div>
              <div class="left-text">
                <!-- 解决方案 -->
                {{$t('m.about.statistics_2')}}
                <span class="text-border"></span>
              </div>
            </div>
            <div class="solution-right">
              <div class="right-text">
                <div style="font-size: 0.43rem;font-weight: 300;text-align: center;">
                  <!-- 匠心 -->
                  {{$t('m.about.idea_title_1')}}
                </div>
                <div style="font-size: 0.29rem;font-weight: 300;text-align: center;width: 3.59rem;margin-top:0.26rem">
                  <!-- 每个细节都考虑周到，用心做出用户体验好的产品 -->
                  {{$t('m.about.idea_text_1')}}
                </div>
              </div>
            </div>
          </div>
          <div ref="animate2" class="cover animate__animated">
            <div class="solution-right">
              <div class="right-text">
                <div style="font-size: 0.43rem;font-weight: 300;text-align: center;">
                  <!-- 创新 -->
                  {{$t('m.about.idea_title_2')}}
                </div>
                <div style="font-size: 0.29rem;font-weight: 300;text-align: center;width: 3.59rem;margin-top:0.26rem">
                  <!-- 鼓励各种可以落地的创新，即使是微创新 -->
                  {{$t('m.about.idea_text_2')}}
                </div>
              </div>
            </div>
            <div class="solution-left">
              <div class="left-num">
                <span style="position: relative;">147 <span style="position: absolute;right:-0.4rem;top:-0.1rem;">+</span></span>
              </div>
              <div class="left-text">
                <!-- 覆盖行业 -->
                {{$t('m.about.statistics_1')}}
                <span class="text-border"></span>
              </div>
            </div>
          </div>
          <div ref="animate3" class="cooperation animate__animated">
            <div class="solution-left">
              <div class="left-num">
                <span style="position: relative;">42 <span style="position: absolute;right:-0.4rem;top:-0.1rem;">+</span></span>
              </div>
              <div class="left-text">
                <!-- 合作伙伴 -->
                {{$t('m.about.statistics_4')}}
                <span class="text-border"></span>
              </div>
            </div>
            <div class="solution-right">
              <div class="right-text">
                <div style="font-size: 0.43rem;font-weight: 300;text-align: center;">
                  <!-- 合作 -->
                  {{$t('m.about.idea_title_3')}}
                </div>
                <div style="font-size: 0.29rem;font-weight: 300;text-align: center;width: 3.59rem;margin-top:0.26rem">
                  <!-- 开放的心态和各类物业公司，供应商展开合作 -->
                  {{$t('m.about.idea_text_3')}}
                  </div>
              </div>
            </div>
          </div>
          <div ref="animate4" class="success animate__animated">
            <div class="solution-right">
              <div class="right-text">
                <div style="font-size: 0.43rem;font-weight: 300;text-align: center;">
                  <!-- 共赢 -->
                  {{$t('m.about.idea_title_4')}}
                </div>
                <div style="font-size: 0.29rem;font-weight: 300;text-align: center;width: 3.59rem;margin-top:0.26rem">
                  <!-- 所有的协议都以共赢未目标，不损害合作方的利益 -->
                  {{$t('m.about.idea_text_4')}}
                </div>
              </div>
            </div>
            <div class="solution-left">
              <div class="left-num">
                <span style="position: relative;">103 <span style="position: absolute;right:-0.4rem;top:-0.1rem;">+</span></span>
              </div>
              <div class="left-text">
                <!-- 成功案列 -->
                {{$t('m.about.statistics_3')}}
                <span class="text-border"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="development-history">
      <div class="enterprise-title">
        <div class="enterprise-title-top">发展历程</div>
        <div class="enterprise-title-bottom">DEVELOPMENT HISTORY</div>
      </div>
      <div class="history-box">
        <div class="item-block" v-for="(item, index) in historyList" :key="index">
          <div style="margin-bottom: 0.5rem; font-size: .49rem;" @click="currentIndex = index">
            {{item.date.substring(0,4)+'年'}}
            <img v-show="currentIndex === index" class="arrow" src="./images/up.png" alt="">
            <img v-show="currentIndex !== index" class="arrow" src="./images/down.png" alt="">
          </div>
          <p v-show="currentIndex === index" v-text="item.remark" class="p-text animate__animated animate__fadeInDown"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { develop_page } from '@/api/synopsis'
export default {
  name: 'Synopsis',
  data() {
    return {
      h1: 0,
      h2: 0,
      h3: 0,
      h4: 0,
      screenHeight: 0,
      historyList: [],
      currentIndex: 0
    }
  },
  created() {
    this._develop_page()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.h1 = this.$refs.animate1.offsetTop
    this.h2 = this.$refs.animate2.offsetTop
    this.h3 = this.$refs.animate3.offsetTop
    this.h4 = this.$refs.animate4.offsetTop
    this.screenHeight = window.screen.height
    console.log(this.h2);
  },
  methods: {
      jumpToHome() {
        this.$router.push('/')
      },
      jumpToHome1() {
        this.$router.push('/home2')
      },    
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var scrollH = scrollTop + this.screenHeight
      if (scrollH < this.h1) {
        this.$refs.animate1.setAttribute('class', 'solution animate__animated')
      } else if (scrollH >= this.h1 && scrollH < this.h2) {
        this.$refs.animate1.setAttribute('class', 'solution animate__animated animate__fadeInRight')
        this.$refs.animate2.setAttribute('class', 'cover animate__animated')
      } else if (scrollH >= this.h2 && scrollH < this.h3) {
        this.$refs.animate2.setAttribute('class', 'cover animate__animated animate__fadeInLeft')
        this.$refs.animate3.setAttribute('class', 'cooperation animate__animated')
      } else if (scrollH >= this.h3 && scrollH < this.h4) {
        this.$refs.animate3.setAttribute('class', 'cooperation animate__animated animate__fadeInRight')
        this.$refs.animate4.setAttribute('class', 'success animate__animated')
      } else if (scrollH >= this.h4) {
        this.$refs.animate4.setAttribute('class', 'success animate__animated animate__fadeInLeft')
      }
    },
    toHome() {
      this.$router.push('/')
    },
    _develop_page() {
      develop_page({ pageNum: 1, pageSize: 50, type: 1 }).then(res => {
        this.historyList = res.data.list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  position: relative;
  padding: 0.26rem 0.4rem 0 0.4rem;
  box-sizing: border-box;
  width: 100%;
  height: 6.05rem;
  background: url('../../static/images/synopsis-bj.png');
  background-size: 100% 100%;
}
.header-img{
  display: flex;
  justify-content: space-between;
}
.header-text{
  width: 4.6rem;
  position: absolute;
  margin-left: -1.6rem;
  top: 52%;
  left: 50%;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3px;
}
.synopsis {
  background-color: #f9f9f9;
  position: relative;
  .synopsis-title {
    position: absolute;
    top: 38%;
    left: 50%;
    width: 4rem;
    height: 0.44rem;
    font-size: 0.56rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    margin-left: -2rem;
  }
  .synopsis-header {
    padding-left: 0.2rem;
    padding-right: 0.4rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 6.03rem;
    background: url("../../static/images/synopsis-bj.png");
    background-size: 100% 100%;
    padding-top: 0.28rem;
    box-sizing: border-box;
    .header-logo {
      .logo {
        width: 3rem;
        font-size: 0.8rem;
        color: #fff;
      }
    }
  }
  .synopsis-content {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    box-sizing: border-box;
    margin-top: 1.25rem;
    .content-enterprise {
      .enterprise-title {
        margin-bottom: 0.8rem;
        .enterprise-title-top {
          text-align: center;
          font-size: 0.48rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #27201b;
          letter-spacing: 0.1rem;
        }
        .enterprise-title-bottom {
          margin-top: -0.5rem;
          font-size: 0.56rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          opacity: 0.1;
          text-align: center;
          letter-spacing: 0.1rem;
        }
      }
      .enterprise-sub {
        font-size: 0.4rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 0.48rem;
        .span-since {
          position: relative;
          width: 2.6rem;
          display: inline-block;
          text-align: center;
          .since {
            display: inline-block;
            background-color: RGBA(255, 171, 10, 0.8);
            width: 2.6rem;
            height: 0.17rem;
            position: absolute;
            left: 0rem;
            bottom: 0.07rem;
          }
        }
      }
      .enterprise-p {
        margin-top: 0.4rem;
        font-size: 0.35rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 0.48rem;
      }
    }
    .content-management {
      margin-top: 1.5rem;
      .enterprise-title {
        margin-bottom: 0.8rem;
        .enterprise-title-top {
          text-align: center;
          font-size: 0.48rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #27201b;
          letter-spacing: 0.1rem;
        }
        .enterprise-title-bottom {
          margin-top: -0.5rem;
          font-size: 0.56rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          opacity: 0.1;
          text-align: center;
          letter-spacing: 0.1rem;
        }
      }
      .management-box {
        .solution {
          display: flex;
          margin-bottom: 1rem;
          .solution-left {
            flex: 1;
            font-size: 0.43rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #27201b;
            .left-num {
              text-align: center;
            }
            .left-text {
              text-align: center;
              position: relative;
              .text-border {
                width: 2rem;
                background: RGBA(255, 171, 10, 0.8);
                display: inline-block;
                height: 0.21rem;
                position: absolute;
                bottom: 0rem;
                left: 30%;
              }
            }
          }
          .solution-right {
            width: 4.23rem;
            height: 3.35rem;
            background: url("../../static/images/synopsis-jx.png");
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            .right-text {
            }
          }
        }
        .cover {
          display: flex;
          margin-bottom: 1rem;
          .solution-left {
            flex: 1;
            font-size: 0.43rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #27201b;
            .left-num {
              text-align: center;
            }
            .left-text {
              text-align: center;
              position: relative;
              .text-border {
                width: 2rem;
                background: RGBA(255, 171, 10, 0.8);
                display: inline-block;
                height: 0.21rem;
                position: absolute;
                bottom: 0rem;
                left: 30%;
              }
            }
          }
          .solution-right {
            width: 4.23rem;
            height: 3.35rem;
            background: url("../../static/images/synopsis-cx.png");
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            .right-text {
            }
          }
        }
        .cooperation {
          display: flex;
          margin-bottom: 1rem;
          .solution-left {
            flex: 1;
            font-size: 0.43rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #27201b;
            .left-num {
              text-align: center;
            }
            .left-text {
              text-align: center;
              position: relative;
              .text-border {
                width: 2rem;
                background: RGBA(255, 171, 10, 0.8);
                display: inline-block;
                height: 0.21rem;
                position: absolute;
                bottom: 0rem;
                left: 30%;
              }
            }
          }
          .solution-right {
            width: 4.23rem;
            height: 3.35rem;
            background: url("../../static/images/synopsis-hz.png");
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            .right-text {
            }
          }
        }
        .success {
          display: flex;
          margin-bottom: 1rem;
          .solution-left {
            flex: 1;
            font-size: 0.43rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #27201b;
            .left-num {
              text-align: center;
            }
            .left-text {
              text-align: center;
              position: relative;
              .text-border {
                width: 2rem;
                background: RGBA(255, 171, 10, 0.8);
                display: inline-block;
                height: 0.21rem;
                position: absolute;
                bottom: 0rem;
                left: 30%;
              }
            }
          }
          .solution-right {
            width: 4.23rem;
            height: 3.35rem;
            background: url("../../static/images/synopsis-gy.png");
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            .right-text {
            }
          }
        }
      }
    }
  }
}
.development-history {
  margin-top: 1.28rem;
  padding: 0.6rem 0.3rem;
  background: url("../../static/images/qyjj-bj.png");
  .enterprise-title {
    margin-bottom: 0.8rem;
    .enterprise-title-top {
      text-align: center;
      font-size: 0.48rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #27201b;
      letter-spacing: 0.1rem;
    }
    .enterprise-title-bottom {
      margin-bottom: 0.85rem;
      margin-top: -0.45rem;
      font-size: 0.56rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      opacity: 0.1;
      text-align: center;
      letter-spacing: 0.05rem;
    }
  }
}
.history-box .item-block {
  padding: 0.5rem 0 0.5rem 0.4rem;
  border-left: 1px solid #ffab0a;
  position: relative;
}
.history-box .item-block::before {
  content: "";
  position: absolute;
  width: 0.24rem;
  height: 0.24rem;
  border-radius: 50%;
  background: rgb(250, 208, 128);
  z-index: 1;
  left: -0.11rem;
  top: 0.68rem;
}
.history-box .item-block::after {
  content: "";
  position: absolute;
  width: 0.17rem;
  height: 0.17rem;
  border-radius: 50%;
  background: rgb(255, 171, 10);
  z-index: 2;
  left: -0.078rem;
  top: 0.71rem;
}
.arrow {
  width: 0.4rem;
}
.p-text {
  color: #666666;
  font-size: 0.37rem;
  background: #fff;
  padding: 0.4rem;
  white-space: pre-wrap;
  line-height: 2;
}
</style>