import request from './request'
/**
 * 企业简介发展历程
 */
export function develop_page(data) {
  return request({
    url: '/web/develop/v1/develop_page',
    method: 'post',
    data
  })
}
/**
 * 合作伙伴
 */
 export function partner_page(data) {
  return request({
    url: '/web/partner/v1/partner_page',
    method: 'post',
    data
  })
}